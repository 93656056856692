import React from "react"
import PropTypes from "prop-types"
import Box from "./box"
import Text from "./text"

const OpeningTimesItem = (props) => {
  const { children, day, time, columnWidth, size, isToday, ...restProps } =
    props

  return (
    <Box display="flex" alignY="center" {...restProps}>
      <Text
        color={isToday ? "primary" : "text"}
        size={size}
        italic={isToday}
        width={columnWidth}
      >
        {day}
      </Text>
      <Text color={isToday ? "primary" : "text"} italic={isToday} size={size}>
        {time}
      </Text>
    </Box>
  )
}

OpeningTimesItem.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  columnWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

OpeningTimesItem.defaultProps = {
  size: "md",
  columnWidth: "144px",
}

export default OpeningTimesItem
